import { File } from "nq";
import { getFile } from "nq/src/domain/File";
import React from "react";

const styles = {
  mainContainer: {
    width: "209mm",
    height: "296mm",

    fontFamily: "Arial, sans-serif",
    padding: 0,
    margin: 0,
    backgroundColor: "white",
    // position: "absolute",
  },
  backgroundImg: {
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundImage: `url(${require("../../../components/image/BVRPageOne.jpg")})`,
    // width: "100%",
    // height: "100%",
    width: "209mm",
    height: "296mm",
  },
  pageLength: {
    height: "100%",
    width: "100%",
  },
  signatureAlignment: {
    display: "flex",
    alignItems: "baseline",
  },
};
// const styles = {
//   mainContainer: {
//     width: "209mm",
//     height: "296mm",
//     fontFamily: "Arial, sans-serif",
//     padding: 0,
//     margin: 0,
//     backgroundColor: "white",
//     // border: "1px solid red",
//     // position: "absolute",
//   },
//   backgroundImg: {
//     backgroundSize: "contain",
//     backgroundPosition: "center",
//     backgroundRepeat: "no-repeat",
//     backgroundImage: `url(${require("../../../components/image/BVRPageOne.jpg")})`,
//     width: "209mm",
//     height: "296mm",
//     // border: "1px solid blue",
//   },
//   pageLength: {
//     height: "100%",
//     width: "100%",
//     // border: "1px solid green",
//   },
//   signatureAlignment: {
//     display: "flex",
//     alignItems: "baseline",
//   },
// };

const headerData = (objects, style, formatText) => {
  return (
    <div
      style={{
        position: "absolute",
        width: "22rem",
        height: "1.3rem",
        fontSize: "7px",
        ...style,
      }}
    >
      {formatText ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <label style={{ marginTop: ".7rem", fontSize: "7px" }}>
            {objects}
          </label>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <label style={{ marginTop: ".7rem", fontSize: "9px" }}>
            {objects}
          </label>
        </div>
      )}
    </div>
  );
};

const titlePage = (title, style) => {
  return (
    <div style={{ top: "12rem", position: "absolute", ...style }}>
      <p
        style={{
          color: "white",
          fontWeight: "bold",
          paddingLeft: "2rem",
        }}
      >
        {title}
      </p>
    </div>
  );
};

const imageLayout = (images) => {
  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        gap: "1rem",
      }}
    >
      {images.map((imageObj, index) => (
        <div
          key={index}
          style={{
            display: "grid",
            width: "45%", // Adjust this as needed
            justifyContent: "center",
            marginBottom: "2rem",
          }}
        >
          <label style={{ textAlign: "center" }}>{imageObj.label}</label>
          <div>
            <img
              src={File.get(
                `${imageObj.image}?applicationId=77c55e9e-2e95-41da-92f1-ccc177ccc59c`
              )}
              style={{
                width: "300px", // Set a fixed width
                height: "300px", // Set a fixed height
                objectFit: "cover", // Ensures the image fits within the box
              }}
              alt={`images-${index}`}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

function Report1({ objects, report, user, questions, labelQuestion }) {
  console.log("Object", objects);

  const repObj = report[0];
  console.log("RepObj: ", repObj);
  console.log("labelQuestion: ", labelQuestion);

  console.log("repObj.uploadRelation", repObj.uploadRelation);

  const formattedText = repObj?.answers[2]?.question?.choices
    .map((item, index) => `${index + 1}. ${item.text}`)
    .join("\n");

  const formattedText2 = repObj?.answers[5]?.question?.choices
    .map((item, index) => `${index + 1}. ${item.text}`)
    .join("\n");

  const formattedRemarks = repObj?.remarks
    .replace("*REMARKS: VERIFIED", "<strong>*REMARKS: VERIFIED</strong>")
    .replace(/\*/g, "<br>*");

  console.log("DFSFSDF: ", questions);
  console.log(
    "IMAGE: ",
    File.get(
      `${objects?.agentSignature}?applicationId=77c55e9e-2e95-41da-92f1-ccc177ccc59c`
    )
  );

  return (
    <>
      <div className="bg-white" style={styles.mainContainer}>
        <div
          style={{
            ...styles.backgroundImg,
          }}
        >
          {/* start code */}
          {/* right  */}
          {headerData(`${objects?.employerName} / ${objects?.borrowerName}`, {
            top: "4.6rem",
            left: "5.5rem",
            fontSize: "7px",
          })}
          {headerData(objects?.employerAddress, {
            top: "6.8rem",
            left: "7rem",
          })}
          {headerData(repObj?.natureBusiness, {
            top: "8.6rem",
            left: "7rem",
          })}
          {headerData(objects?.loanTitle, {
            top: "10rem",
            left: "7rem",
          })}

          {/* left  */}
          {headerData(objects?.timeline[2].time, {
            top: "4.6rem",
            left: "34rem",
            width: "12.5rem",
          })}
          {headerData(repObj?.answers[0]?.choices[0]?.text, {
            top: "6.8rem",
            left: "34rem",
            width: "12.5rem",
          })}
          {headerData(repObj?.operational, {
            top: "8.6rem",
            left: "34rem",
            width: "12.5rem",
          })}
          {headerData(repObj?.requesting, {
            top: "10rem",
            left: "34rem",
            width: "12.5rem",
          })}

          {titlePage("INFORMANTS INTERVIEWED")}
          {/* change object  */}
          {headerData(repObj?.answers[0]?.question?.question, {
            top: "14.3rem",
            left: "4rem",
            width: "38rem",
            display: "flex",
          })}
          {/* {headerData(repObj?.answers[0]?.choices[0]?.text, {
            top: "15.5rem",
            left: "4rem",
            width: "38rem",
            display: "flex",
          })} */}
          {headerData(repObj?.answers[0]?.choices[0]?.text, {
            top: "15.5rem",
            left: "4rem",
            width: "38rem",
            display: "flex",
          })}

          {headerData(repObj?.answers[1]?.question?.question, {
            top: "14.3rem",
            left: "21.5rem",
            width: "17rem",
            display: "flex",
          })}
          {headerData(repObj?.answers[1]?.choices[0]?.text, {
            top: "15.5rem",
            left: "21.5rem",
            width: "17rem",
            display: "flex",
          })}

          {titlePage(labelQuestion[0]?.header, {
            top: "18.5rem",
            color: "red",
          })}
          {headerData(repObj.answers[2]?.question?.question, {
            top: "19.5rem",
            left: "2rem",
            width: "38rem",
            fontWeight: "bold",
            display: "flex",
          })}

          {headerData(repObj.answers[2]?.answers, {
            top: "19.5rem",
            left: "41.3rem",
            width: "5.4rem",
            fontWeight: "bold",
          })}

          {headerData(
            formattedText,
            {
              top: "20.8rem",
              height: "4.3rem",
              left: "2rem",
              width: "38rem",
              display: "flex",
              whiteSpace: "pre-wrap",
            },
            true
          )}
          {/* num 2 */}
          {headerData(questions[0]?.questionYes, {
            top: "25rem",
            left: "2rem",
            width: "38rem",
            fontWeight: "bold",
            display: "flex",
          })}

          {headerData(repObj.answers[3]?.answers, {
            top: "25rem",
            left: "41.3rem",
            width: "5.4rem",
            fontWeight: "bold",
          })}
          {/* num 3 */}
          {headerData(questions[1]?.questionYes, {
            top: "26.5rem",
            left: "2rem",
            width: "38rem",
            fontWeight: "bold",
            display: "flex",
          })}
          {headerData(repObj?.answers[4]?.answers, {
            top: "26.5rem",
            left: "41.3rem",
            width: "5.4rem",
            fontWeight: "bold",
          })}

          {headerData(repObj?.answers[5]?.question?.question, {
            top: "27.6rem",
            left: "2rem",
            width: "38rem",
            fontWeight: "bold",
            display: "flex",
          })}

          {headerData(repObj?.answers[5]?.answers, {
            top: "27.6rem",
            left: "41.3rem",
            width: "5.4rem",
            fontWeight: "bold",
          })}

          {headerData(
            formattedText2,
            {
              top: "28.8rem",
              height: "4.3rem",
              left: "2rem",
              width: "38rem",
              display: "flex",
              whiteSpace: "pre-wrap",
            },
            true
          )}
          {/* num 5  */}
          {headerData(questions[2]?.questionYes, {
            top: "32.3rem",
            left: "2rem",
            width: "38rem",
            fontWeight: "bold",
            display: "flex",
          })}
          {headerData(repObj?.answers[6]?.answers, {
            top: "32.3rem",
            left: "41.3rem",
            width: "5.4rem",
            fontWeight: "bold",
          })}

          {headerData(
            repObj?.answers[7]?.question?.question,
            {
              top: "35rem",
              left: "3.8rem",
              width: "16.5rem",
              display: "flex",
            },
            true
          )}
          {headerData(
            repObj?.answers[8]?.question?.question,
            {
              top: "35rem",
              left: "20.8rem",
              width: "9rem",
              display: "flex",
            },
            true
          )}
          {headerData(
            repObj?.answers[9]?.question?.question,
            {
              top: "35rem",
              left: "30.4rem",
              width: "8.5rem",
              display: "flex",
            },
            true
          )}

          {headerData(repObj?.answers[7]?.choices[0]?.text, {
            top: "36.2rem",
            left: "3.8rem",
            width: "16.5rem",
            display: "flex",
          })}
          {headerData(repObj?.answers[8]?.choices[0]?.text, {
            top: "36.2rem",
            left: "20.8rem",
            width: "9rem",
            display: "flex",
          })}
          {headerData(repObj?.answers[9]?.choices[0]?.text, {
            top: "36.2rem",
            left: "30.4rem",
            width: "8.5rem",
            display: "flex",
          })}

          {headerData(repObj?.answers[7]?.choices[1]?.text, {
            top: "37.8rem",
            left: "3.8rem",
            width: "16.5rem",
            display: "flex",
          })}
          {headerData(repObj?.answers[8]?.choices[1]?.text, {
            top: "37.8rem",
            left: "20.8rem",
            width: "9rem",
            display: "flex",
          })}
          {headerData(repObj?.answers[9]?.choices[1]?.text, {
            top: "37.8rem",
            left: "30.4rem",
            width: "8.5rem",
            display: "flex",
          })}
          {/* num 7 */}
          {headerData(questions[3]?.questionYes, {
            top: "40rem",
            left: "2rem",
            width: "38rem",
            fontWeight: "bold",
            display: "flex",
          })}
          {headerData(repObj?.answers[10]?.answers, {
            top: "40rem",
            left: "41.3rem",
            width: "5.4rem",
            fontWeight: "bold",
          })}

          {headerData(repObj?.answers[11]?.question?.question, {
            top: "42.5rem",
            left: "4.5rem",
            width: "16.5rem",
            display: "flex",
          })}
          {headerData(repObj?.answers[12]?.question?.question, {
            top: "42.5rem",
            left: "20.9rem",
            width: "16.5rem",
            display: "flex",
          })}

          {headerData(repObj?.answers[11]?.choices[0]?.text, {
            top: "43.9rem",
            left: "3.5rem",
            width: "16.5rem",
          })}
          {headerData(repObj?.answers[12]?.choices[0]?.text, {
            top: "43.9rem",
            left: "20.3rem",
            width: "16.5rem",
          })}

          {/* num 8 */}
          {headerData(questions[4]?.questionYes, {
            top: "46.3rem",
            left: "2rem",
            width: "38rem",
            fontWeight: "bold",
            display: "flex",
          })}
          {headerData(repObj?.answers[13]?.answers, {
            top: "46.3rem",
            left: "41.3rem",
            width: "5.4rem",
            fontWeight: "bold",
          })}

          {headerData(repObj?.answers[14]?.question?.question, {
            top: "48.9rem",
            left: "3.8rem",
            width: "16.5rem",
            display: "flex",
          })}
          {headerData(repObj?.answers[15]?.question?.question, {
            top: "48.9rem",
            left: "20.8rem",
            width: "16.5rem",
            display: "flex",
          })}

          {headerData(repObj?.answers[14]?.choices[0]?.text, {
            top: "50rem",
            left: "3.5rem",
            width: "16.5rem",
          })}
          {headerData(repObj?.answers[15]?.choices[0]?.text, {
            top: "50rem",
            left: "20.3rem",
            width: "16.5rem",
          })}

          {/* num 9 */}
          {headerData(questions[5]?.questionYes, {
            top: "54rem",
            left: "2rem",
            width: "38rem",
            fontWeight: "bold",
            display: "flex",
          })}

          {headerData(repObj?.answers[16]?.answers, {
            top: "54rem",
            left: "41.3rem",
            width: "5.4rem",
            fontWeight: "bold",
          })}

          {headerData(repObj?.answers[17]?.question?.question, {
            top: "56.4rem",
            left: "3.9rem",
            width: "16.5rem",
            display: "flex",
          })}
          {headerData(repObj?.answers[17]?.choices[0]?.text, {
            top: "57.6rem",
            left: "3.5rem",
            width: "16.5rem",
          })}

          {headerData(labelQuestion[1]?.questionYes, {
            top: "60.7rem",
            left: "2rem",
            width: "16.5rem",
            fontWeight: "bold",
            display: "flex",
          })}
          {headerData(repObj?.answers[18]?.choices[0]?.text, {
            top: "60.7rem",
            left: "20rem",
            width: "5rem",
            display: "flex",
          })}

          {headerData(repObj?.answers[19]?.question?.question, {
            top: "60.7rem",
            left: "30.5rem",
            width: "16.5rem",
            fontWeight: "bold",
            display: "flex",
          })}

          {headerData(repObj?.answers[19]?.choices[0]?.text, {
            top: "60.7rem",
            left: "37rem",
            width: "5rem",
            display: "flex",
          })}

          {/* num 10 */}
          {headerData(questions[6]?.questionYes, {
            top: "62.3rem",
            left: "2rem",
            width: "38rem",
            fontWeight: "bold",
            display: "flex",
          })}
          {headerData(repObj?.answers[19]?.answers, {
            top: "62.3rem",
            left: "41.3rem",
            width: "5.4rem",
            fontWeight: "bold",
          })}
          {headerData(repObj?.answers[21]?.question?.question, {
            top: "64.8rem",
            left: "3.5rem",
            width: "16.5rem",
            display: "flex",
          })}

          {headerData(repObj?.answers[21]?.choices[0]?.text, {
            top: "65.5rem",
            left: "3.2rem",
            width: "16.5rem",
          })}

          {headerData(repObj?.answers[21]?.answers, {
            top: "61.4rem",
            left: "41.3rem",
            width: "5.4rem",
            fontWeight: "bold",
          })}
        </div>
      </div>
      {/* page 2  */}
      <div
        className="bg-white"
        style={{
          ...styles.mainContainer,
          // marginTop: "1.5rem",
        }}
      >
        <div
          style={{
            ...styles.backgroundImg,
            backgroundImage: `url(${require("../../../components/image/BVRPage2.jpg")})`,
            width: "97%",
            marginLeft: ".8rem",
          }}
        >
          {/* start code */}
          {/* num 12  */}
          {headerData(labelQuestion[3]?.questionYes, {
            top: "72.5rem",
            left: "2rem",
            width: "38rem",
            fontWeight: "bold",
            display: "flex",
          })}
          {headerData(repObj.answers[22]?.question?.question, {
            top: "75.2rem",
            left: "3.4rem",
            width: "9.5rem",
            display: "flex",
          })}

          {headerData(repObj.answers[22]?.choices[0]?.text, {
            top: "76.4rem",
            left: "3.4rem",
            width: "9.5rem",
            display: "flex",
          })}
          {headerData(repObj.answers[22]?.choices[1]?.text, {
            top: "77.6rem",
            left: "3.4rem",
            width: "9.5rem",
            display: "flex",
          })}
          {headerData(repObj.answers[22]?.choices[2]?.text, {
            top: "78.8rem",
            left: "3.4rem",
            width: "9.5rem",
            display: "flex",
          })}

          {headerData(repObj.answers[23]?.question.question, {
            top: "75.2rem",
            left: "13.6rem",
            width: "9.5rem",
            display: "flex",
          })}

          {headerData(repObj.answers[23]?.choices[0]?.text, {
            top: "76.4rem",
            left: "13.6rem",
            width: "9.5rem",
            display: "flex",
          })}
          {headerData(repObj.answers[23]?.choices[1]?.text, {
            top: "77.6rem",
            left: "13.6rem",
            width: "9.5rem",
            display: "flex",
          })}
          {headerData(repObj.answers[23]?.choices[2]?.text, {
            top: "78.8rem",
            left: "13.6rem",
            width: "9.5rem",
            display: "flex",
          })}

          {headerData(repObj.answers[24]?.question.question, {
            top: "75.2rem",
            left: "20.3rem",
            width: "17.5rem",
          })}

          {headerData(repObj.answers[24]?.choices[0]?.text, {
            top: "76.4rem",
            left: "20.3rem",
            width: "17.5rem",
          })}
          {headerData(repObj.answers[24]?.choices[1]?.text, {
            top: "77.6rem",
            left: "20.3rem",
            width: "17.5rem",
          })}
          {headerData(repObj.answers[24]?.choices[2]?.text, {
            top: "78.8rem",
            left: "20.3rem",
            width: "17.5rem",
          })}

          {/* 13 */}
          {headerData(labelQuestion[4]?.questionYes, {
            top: "81.4rem",
            left: "2rem",
            width: "38rem",
            fontWeight: "bold",
            display: "flex",
          })}
          {headerData(repObj.answers[25]?.question.question, {
            top: "84rem",
            left: "3.4rem",
            width: "9.8rem",
            display: "flex",
          })}

          {headerData(repObj.answers[25]?.choices[0]?.text, {
            top: "87.6rem",
            left: "3.4rem",
            width: "9.8rem",
            display: "flex",
          })}
          {headerData(repObj.answers[25]?.choices[1]?.text, {
            top: "90.5rem",
            left: "3.4rem",
            width: "9.8rem",
            display: "flex",
          })}
          {headerData(repObj.answers[25]?.choices[2]?.text, {
            top: "92.4rem",
            left: "3.4rem",
            width: "9.8rem",
            display: "flex",
          })}

          {headerData(repObj.answers[26]?.question.question, {
            top: "84rem",
            left: "14.3rem",
            width: "9.8rem",
            display: "flex",
          })}
          {headerData(repObj.answers[26]?.choices[0]?.text, {
            top: "87.6rem",
            left: "14.3rem",
            width: "9.8rem",
            display: "flex",
          })}
          {headerData(repObj.answers[26]?.choices[1]?.text, {
            top: "90.5rem",
            left: "14.3rem",
            width: "9.8rem",
            display: "flex",
          })}
          {headerData(repObj.answers[26]?.choices[2]?.text, {
            top: "93.4rem",
            left: "14.3rem",
            width: "9.8rem",
            display: "flex",
          })}

          {headerData(repObj.answers[27]?.question.question, {
            top: "84rem",
            left: "21rem",
            width: "9.8rem",
            display: "flex",
          })}
          {headerData(repObj.answers[27]?.choices[0]?.text, {
            top: "87.6rem",
            left: "21rem",
            width: "9.8rem",
            display: "flex",
          })}
          {headerData(repObj.answers[27]?.choices[1]?.text, {
            top: "90.5rem",
            left: "21rem",
            width: "9.8rem",
            display: "flex",
          })}
          {headerData(repObj.answers[27]?.choices[2]?.text, {
            top: "93.4rem",
            left: "21rem",
            width: "9.8rem",
            display: "flex",
          })}

          {/* {headerData(repObj.answers[25]?.choices[2]?.text, {
            top: "22rem",
            left: "13.3rem",
            width: "9.8rem",
            display: "flex",
          })} */}

          {headerData(repObj.answers[28]?.question.question, {
            top: "84rem",
            left: "30.2rem",
            width: "9.8rem",
            display: "flex",
          })}
          {headerData(repObj.answers[28]?.choices[0]?.text, {
            top: "87.6rem",
            left: "30.2rem",
            width: "9.8rem",
            display: "flex",
          })}
          {headerData(repObj.answers[28]?.choices[1]?.text, {
            top: "90.5rem",
            left: "30.2rem",
            width: "9.8rem",
            display: "flex",
          })}
          {headerData(repObj.answers[28]?.choices[2]?.text, {
            top: "93.4rem",
            left: "30.2rem",
            width: "9.8rem",
            display: "flex",
          })}
          {/* 14 */}
          {headerData(labelQuestion[2]?.questionYes, {
            top: "97rem",
            left: "2rem",
            width: "10rem",
            fontWeight: "bold",
            display: "flex",
          })}
          {headerData(repObj.answers[29]?.choices[0]?.text, {
            top: "97rem",
            left: "13.4rem",
            width: "16.8rem",
            display: "flex",
          })}
          {/* remarks  */}
          {headerData(
            <div dangerouslySetInnerHTML={{ __html: formattedRemarks }} />,
            {
              top: "100rem",
              left: "3.4rem",
              width: "34rem",
              height: "35rem",
              display: "flex",
            }
          )}
        </div>
      </div>
      {/* page 3  */}
      <div
        className="bg-white"
        style={{ ...styles.mainContainer, marginTop: "2rem" }}
      >
        {imageLayout(repObj.uploadRelation)}
        {/* start code */}
      </div>
      {/* page 4 */}
      <div
        className="bg-white"
        //145, 74, 0
        style={{
          ...styles.mainContainer,
          marginTop:
            repObj.uploadRelation.length <= 6
              ? "5rem"
              : repObj.uploadRelation.length >= 7 &&
                repObj.uploadRelation.length <= 12
              ? "74rem"
              : "145rem",
        }}
      >
        {/* start code */}
        <div style={styles.pageLength}>
          <div style={styles.signatureAlignment}>
            <div
              style={{
                width: "50%",
                display: "flex",
                alignItems: "baseline",
                justifyContent: "center",
              }}
            >
              <label style={{ fontSize: "12px" }}>CREDIT INVESTIGATOR </label>
              <div
                className="text-center"
                style={{ borderBottom: "2px solid", width: "15rem" }}
              >
                <img
                  src={File.get(
                    `${objects?.agentSignature}?applicationId=77c55e9e-2e95-41da-92f1-ccc177ccc59c`
                  )}
                  height={100}
                  width={300}
                  alt="Signature"
                />
                <p className="text-center">{objects?.agent}</p>
              </div>
            </div>
            <div
              style={{
                width: "50%",
                display: "flex",
                alignItems: "baseline",
                justifyContent: "center",
              }}
            >
              <label style={{ fontSize: "12px" }}>Date: </label>
              <div style={{ borderBottom: "2px solid", width: "15rem" }}>
                {objects?.dateTimeSubmitted}
              </div>
            </div>
          </div>
          <div style={styles.signatureAlignment}>
            <div
              style={{
                width: "50%",
                display: "flex",
                alignItems: "baseline",
                justifyContent: "center",
              }}
            >
              <label style={{ fontSize: "12px" }}>REVIEWED BY: </label>
              <div
                className="text-center"
                style={{ borderBottom: "2px solid", width: "15rem" }}
              >
                <img
                  src={File.get(
                    `${objects?.qaSignature}?applicationId=77c55e9e-2e95-41da-92f1-ccc177ccc59c`
                  )}
                  height="50"
                  width="50"
                  alt="Signature"
                />
                <p>{objects?.qaOfficer}</p>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div style={{ width: "25rem" }}>
              <p>
                <b>
                  Forbes Financial Consultancy Corporation Unit 3405, 34/F
                  Raffles Corporate Center, Ortigas Center, Pasig City Tel No.
                  636-3053
                </b>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Report1;

// import React from "react";

// const styles = {
//   mainContainer: {
//     width: "210mm",
//     height: "297mm",
//     fontFamily: "Arial, sans-serif",
//     padding: 0,
//     margin: 0,
//     backgroundColor: "white",
//     // position: "absolute",
//   },
//   backgroundImg: {
//     backgroundSize: "contain",
//     backgroundPosition: "center",
//     backgroundRepeat: "no-repeat",
//     backgroundImage: `url(${require("../../../components/image/BVRPageOne.jpg")})`,
//     width: "210mm",
//     height: "297mm",
//   },
//   pageLength: {
//     height: "100%",
//     width: "100%",
//   },
//   signatureAlignment: {
//     display: "flex",
//     alignItems: "baseline",
//   },
// };

// function Report1() {
//   return (
//     <div>
//       <div style={styles.mainContainer}>
//         <div
//           style={{
//             ...styles.backgroundImg,
//           }}
//         >
//           Report 1
//         </div>
//       </div>
//       <div style={styles.mainContainer}>
//         <div
//           style={{
//             ...styles.backgroundImg,
//             backgroundImage: `url(${require("../../../components/image/BVRPage2.jpg")})`,
//           }}
//         >
//           Report 2
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Report1;
