// import { File } from "nq";
// import React from "react";

// const styles = {
//   mainContainer: {
//     width: "296mm",
//     height: "209mm",
//     fontFamily: "Arial, sans-serif",
//     padding: 0,
//     margin: 0,
//     backgroundColor: "white",

//     // position: "absolute",
//   },
//   backgroundImg: {
//     backgroundImage: `url(${require("../../../components/image/printBG.jpg")})`,
//     backgroundSize: "cover",
//     backgroundPosition: "center",
//     width: "296mm",
//     height: "209mm",
//   },
//   checkboxContainer: {
//     width: "28px",
//     height: "20px",
//     left: "3.7rem",
//     position: "absolute",
//     backgroundColor: "#000",
//   },
//   checkboxContainerStatus: {
//     width: "28px",
//     height: "20px",
//     position: "absolute",
//     left: "25.6rem",
//     backgroundColor: "#000",
//   },
//   remarks: {
//     position: "absolute",
//     left: "38rem",
//     top: "13rem",
//     height: "27.5rem",
//     padding: "1rem",
//     width: "28.5rem",
//     display: "flex",
//     alignItems: "center",
//     fontSize: "10px",
//   },

//   pageLength: {
//     // border: "3px solid",
//     // height: "200rem",
//     // width: "68rem",
//     height: "100%",
//     width: "100%",
//   },
//   signatureAlignment: {
//     // border: "3px solid",
//     display: "flex",
//     paddingLeft: "2rem",
//     alignItems: "baseline",
//   },
// };

// const imageLayout = (images) => {
//   return (
//     <div
//       style={{
//         display: "flex",
//         flexWrap: "wrap",
//         justifyContent: "center",
//         gap: "1rem",
//       }}
//     >
//       {images.map((imageObj, index) => (
//         <div
//           key={index}
//           style={{
//             display: "grid",
//             width: "45%", // Adjust this as needed
//             justifyContent: "center",
//             marginBottom: "2rem",
//           }}
//         >
//           <label style={{ textAlign: "center" }}>{imageObj.label}</label>
//           <div>
//             <img
//               src={File.get(
//                 `${imageObj.image}?applicationId=77c55e9e-2e95-41da-92f1-ccc177ccc59c`
//               )}
//               style={{
//                 width: "300px", // Set a fixed width
//                 height: "300px", // Set a fixed height
//                 objectFit: "cover", // Ensures the image fits within the box
//               }}
//               alt={`images-${index}`}
//             />
//           </div>
//         </div>
//       ))}
//     </div>
//   );
// };

// function Report2({ objects, report, user }) {
//   console.log("report show", report);
//   // const categories = report[0]?.categories || [];
//   // const half = Math.ceil(categories.length / 2);
//   const object = report[0];

//   // const formattedRemarks = object?.remarks
//   //   .replace("*REMARKS: VERIFIED", "<strong>*REMARKS: VERIFIED</strong>")
//   //   .replace(/\*/g, "<br>*");
//   const formattedRemarks = object?.remarks
//     .replace("COMPANY NAME", "<strong>COMPANY NAME</strong>")
//     .replace("INFORMANT", "<strong>INFORMANT</strong>")
//     .replace("POSITION", "<strong>POSITION</strong>")
//     .replace("DATE HIRED", "<strong>DATE HIRED</strong>")
//     .replace("PHP", "<strong>PHP</strong>")
//     .replace(/\n/g, "<br>");

//   const checkBoxRANK = () => {
//     const checkBox = object.answers[3]?.question?.question;
//     // console.log("checked: ", checkBox);
//     switch (checkBox) {
//       case "RANK & FILE":
//         return (
//           <div
//             style={{
//               ...styles.checkboxContainer,
//               top: "15.2rem",
//             }}
//           />
//         );
//       case "SUPERVISOR":
//         return (
//           <div
//             style={{
//               ...styles.checkboxContainer,
//               top: "16.4rem",
//             }}
//           />
//         );
//       case "MANAGER":
//         return (
//           <div
//             style={{
//               ...styles.checkboxContainer,
//               top: "17.5rem",
//             }}
//           />
//         );
//       case "PROFESSIONAL":
//         return (
//           <div
//             style={{
//               ...styles.checkboxContainer,
//               top: "18.8rem",
//             }}
//           />
//         );
//       default:
//         return <div>Unknown report type</div>;
//     }
//   };

//   const checkBoxSTATUS = () => {
//     const checkBox = object.answers[4]?.question?.question;
//     // console.log("checked: ", checkBox);
//     switch (checkBox) {
//       case "REGULAR":
//         return (
//           <div
//             style={{
//               ...styles.checkboxContainerStatus,
//               top: "15.2rem",
//             }}
//           />
//         );
//       case "PROBATIONARY":
//         return (
//           <div
//             style={{
//               ...styles.checkboxContainerStatus,
//               top: "16.6rem",
//             }}
//           />
//         );
//       case "CONTRACTUAL":
//         return (
//           <div
//             style={{
//               ...styles.checkboxContainerStatus,
//               top: "17.6rem",
//             }}
//           />
//         );
//       case "SELF-EMPLOYED":
//         return (
//           <div
//             style={{
//               ...styles.checkboxContainerStatus,
//               top: "18.9rem",
//             }}
//           />
//         );
//       case "COMMISSION-BASED":
//         return (
//           <div
//             style={{
//               ...styles.checkboxContainerStatus,
//               marginTop: "19.4rem",
//             }}
//           />
//         );
//       default:
//         return null;
//     }
//   };

//   console.log("OBJECT: ", object);
//   return (
//     <>
//       <div style={styles.mainContainer}>
//         <div style={styles.backgroundImg}>
//           <p
//             style={{
//               top: "3.4rem",
//               left: "11rem",
//               position: "absolute",
//             }}
//           >
//             {object.accountName}
//           </p>
//           <p
//             style={{
//               top: "7.7rem",
//               left: "11rem",
//               position: "absolute",
//             }}
//           >
//             {object?.answers[0]?.answer}
//           </p>
//           <p
//             style={{
//               top: "10rem",
//               left: "14.8rem",
//               position: "absolute",
//             }}
//           >
//             {object?.answers[1]?.answer}
//           </p>
//           <p
//             style={{
//               top: "12.3rem",
//               left: "10rem",
//               position: "absolute",
//             }}
//           >
//             {object?.answers[2]?.answer}
//           </p>

//           {checkBoxRANK()}
//           {checkBoxSTATUS()}

//           <p
//             style={{
//               top: "23.9rem",
//               left: "16.5rem",
//               position: "absolute",
//             }}
//           >
//             {object?.answers[5]?.answer}
//           </p>
//           <p
//             style={{
//               top: "26.4rem",
//               left: "16.3rem",
//               position: "absolute",
//             }}
//           >
//             {object?.answers[6]?.answer}
//           </p>
//           <p
//             style={{
//               top: "27.9rem",
//               left: "16.3rem",
//               position: "absolute",

//               width: "23.5rem",
//               display: "flex",
//             }}
//           >
//             {object?.answers[7]?.answer}
//           </p>
//           <p
//             style={{
//               top: "32.4rem",
//               left: "16.3rem",
//               position: "absolute",
//             }}
//           >
//             {object?.answers[8]?.answer}
//           </p>
//           <p
//             style={{
//               top: "34.1rem",
//               left: "16.3rem",
//               position: "absolute",
//             }}
//           >
//             {object?.answers[9]?.answer}
//           </p>
//           <p
//             style={{
//               top: "35.9rem",
//               left: "16.3rem",
//               position: "absolute",
//             }}
//           >
//             {object?.answers[10]?.answer}
//           </p>

//           <div style={styles.remarks}>
//             {/* <span>{object.remarks}</span> */}
//             <div dangerouslySetInnerHTML={{ __html: formattedRemarks }} />
//           </div>
//         </div>
//       </div>
//       <div style={{ ...styles.mainContainer, top: "60rem", marginTop: "10px" }}>
//         <div style={styles.pageLength}>
//           <div style={styles.signatureAlignment}>
//             <div
//               style={{ width: "50%", display: "flex", alignItems: "baseline" }}
//             >
//               <label>CREDIT INVESTIGATOR </label>
//               <div style={{ borderBottom: "2px solid", width: "20rem" }}>
//                 <img
//                   // src={objects?.agentSignature}
//                   src={File.get(
//                     `${objects?.agentSignature}?applicationId=77c55e9e-2e95-41da-92f1-ccc177ccc59c`
//                   )}
//                   height={100}
//                   width={300}
//                   alt="Signature"
//                 />
//               </div>
//             </div>
//             <div
//               style={{ width: "50%", display: "flex", alignItems: "baseline" }}
//             >
//               <label>Date: </label>
//               <div style={{ borderBottom: "2px solid", width: "20rem" }}>
//                 {objects.dateTimeSubmitted}
//               </div>
//             </div>
//           </div>
//           <div style={styles.signatureAlignment}>
//             <div
//               style={{ width: "50%", display: "flex", alignItems: "baseline" }}
//             >
//               <label>REVIEWED BY: </label>
//               <div style={{ borderBottom: "2px solid", width: "20rem" }}>
//                 <img
//                   // src={objects?.agentSignature}
//                   src={File.get(
//                     `${objects?.agentSignature}?applicationId=77c55e9e-2e95-41da-92f1-ccc177ccc59c`
//                   )}
//                   height={100}
//                   width={300}
//                   alt="Signature"
//                 />
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       <div style={{ ...styles.mainContainer, marginTop: "10px" }}>
//         <div style={styles.pageLength}>
//           {imageLayout(object.uploadRelation)}
//         </div>
//       </div>
//       <div style={{ ...styles.mainContainer, marginTop: "10px" }}>
//         <div style={styles.pageLength}>
//           {imageLayout(object.uploadRelation)}
//         </div>
//       </div>
//     </>
//   );
// }

// export default Report2;
import { File } from "nq";
import React from "react";

const styles = {
  mainContainer: {
    width: "296mm",
    height: "209mm",
    fontFamily: "Arial, sans-serif",
    padding: 0,
    margin: 0,
    backgroundColor: "white",
  },
  backgroundImg: {
    backgroundImage: `url(${require("../../../components/image/printBG.jpg")})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "296mm",
    height: "209mm",
  },
  checkboxContainer: {
    width: "28px",
    height: "20px",
    left: "3.7rem",
    position: "absolute",
    backgroundColor: "#000",
  },
  checkboxContainerStatus: {
    width: "28px",
    height: "20px",
    position: "absolute",
    left: "25.6rem",
    backgroundColor: "#000",
  },
  remarks: {
    position: "absolute",
    left: "38rem",
    top: "13rem",
    height: "27.5rem",
    padding: "1rem",
    width: "28.5rem",
    display: "flex",
    alignItems: "center",
    fontSize: "10px",
  },
  pageLength: {
    height: "100%",
    width: "100%",
  },
  signatureAlignment: {
    display: "flex",
    paddingLeft: "2rem",
    alignItems: "baseline",
  },
};

// Function to split images into chunks of 6
const chunkArray = (array, chunkSize) => {
  const result = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    result.push(array.slice(i, i + chunkSize));
  }
  return result;
};

const imageLayout = (images) => {
  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        gap: "1rem",
      }}
    >
      {images.map((imageObj, index) => (
        <div
          key={index}
          style={{
            display: "grid",
            width: "45%",
            justifyContent: "center",
            marginBottom: "2rem",
          }}
        >
          <label style={{ textAlign: "center" }}>{imageObj.label}</label>
          <div>
            <img
              src={File.get(
                `${imageObj.image}?applicationId=77c55e9e-2e95-41da-92f1-ccc177ccc59c`
              )}
              style={{
                width: "300px",
                height: "300px",
                objectFit: "cover",
              }}
              alt={`images-${index}`}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

function Report2({ objects, report, user }) {
  const object = report[0];

  const formattedRemarks = object?.remarks
    .replace("COMPANY NAME", "<strong>COMPANY NAME</strong>")
    .replace("INFORMANT", "<strong>INFORMANT</strong>")
    .replace("POSITION", "<strong>POSITION</strong>")
    .replace("DATE HIRED", "<strong>DATE HIRED</strong>")
    .replace("PHP", "<strong>PHP</strong>")
    .replace(/\n/g, "<br>");

  const checkBoxRANK = () => {
    const checkBox = object.answers[3]?.question?.question;
    console.log("check", checkBox);
    switch (checkBox) {
      case "RANK & FILE":
        return (
          <div
            style={{
              ...styles.checkboxContainer,
              top: "15.2rem",
            }}
          />
        );
      case "SUPERVISOR":
        return (
          <div
            style={{
              ...styles.checkboxContainer,
              top: "16.4rem",
            }}
          />
        );
      case "MANAGER":
        return (
          <div
            style={{
              ...styles.checkboxContainer,
              top: "17.5rem",
            }}
          />
        );
      case "PROFESSIONAL":
        return (
          <div
            style={{
              ...styles.checkboxContainer,
              top: "18.8rem",
            }}
          />
        );
      case "OTHERS :":
        return (
          <div
            style={{
              ...styles.checkboxContainer,
              top: "20rem",
            }}
          />
        );
      default:
        return <div></div>;
    }
  };

  const checkBoxSTATUS = () => {
    const checkBox = object.answers[4]?.question?.question;
    switch (checkBox) {
      case "REGULAR":
        return (
          <div
            style={{
              ...styles.checkboxContainerStatus,
              top: "15.2rem",
            }}
          />
        );
      case "PROBATIONARY":
        return (
          <div
            style={{
              ...styles.checkboxContainerStatus,
              top: "16.6rem",
            }}
          />
        );
      case "CONTRACTUAL":
        return (
          <div
            style={{
              ...styles.checkboxContainerStatus,
              top: "17.6rem",
            }}
          />
        );
      case "SELF-EMPLOYED":
        return (
          <div
            style={{
              ...styles.checkboxContainerStatus,
              top: "18.9rem",
            }}
          />
        );
      case "COMMISSION-BASED":
        return (
          <div
            style={{
              ...styles.checkboxContainerStatus,
              marginTop: "19.4rem",
            }}
          />
        );
      default:
        return null;
    }
  };

  // Split the images into chunks of 6
  const imageChunks = chunkArray(object.uploadRelation, 4);

  return (
    <>
      <div style={styles.mainContainer}>
        <div style={styles.backgroundImg}>
          {/* Your dynamic text rendering */}
          <p style={{ top: "3.4rem", left: "11rem", position: "absolute" }}>
            {object.accountName}
          </p>
          <p style={{ top: "7.7rem", left: "11rem", position: "absolute" }}>
            {object?.answers[0]?.answer}
          </p>
          <p style={{ top: "10rem", left: "14.8rem", position: "absolute" }}>
            {object?.answers[1]?.answer}
          </p>
          <p style={{ top: "12.3rem", left: "10rem", position: "absolute" }}>
            {object?.answers[2]?.answer}
          </p>

          {checkBoxRANK()}
          {checkBoxSTATUS()}

          <p style={{ top: "23.9rem", left: "16.5rem", position: "absolute" }}>
            {object?.answers[5]?.answer}
          </p>
          {/* <p
            style={{
              top: "25.1rem",
              left: "16.3rem",
              position: "absolute",
              width: "23rem",
              display: "flex",
            }}
          >
            {object?.answers[6]?.answer}
          </p> */}
          <p
            style={{
              top:
                object?.answers[6]?.answer.length > 47 ? "25.1rem" : "26.5rem", // Adjust top based on length or width
              left: "16.3rem",
              position: "absolute",
              width: "23rem",
              display: "flex",
              whiteSpace: "nowrap", // Ensures the text doesn't wrap
              overflow: "hidden", // Prevents overflow
              textOverflow: "ellipsis", // Adds ellipsis if the text is too long
            }}
          >
            {object?.answers[6]?.answer}
          </p>

          <p
            style={{
              top: "27.9rem",
              left: "16.3rem",
              position: "absolute",
              width: "23rem",
              display: "flex",
            }}
          >
            {object?.answers[7]?.answer}
          </p>
          <p style={{ top: "32.4rem", left: "16.3rem", position: "absolute" }}>
            {object?.answers[8]?.answer}
          </p>
          <p style={{ top: "34.1rem", left: "16.3rem", position: "absolute" }}>
            {object?.answers[9]?.answer}
          </p>
          <p style={{ top: "35.9rem", left: "16.3rem", position: "absolute" }}>
            {object?.answers[10]?.answer}
          </p>

          <div style={styles.remarks}>
            <div dangerouslySetInnerHTML={{ __html: formattedRemarks }} />
          </div>
        </div>
      </div>
      <div style={{ ...styles.mainContainer, marginTop: "10px" }}>
        <div style={styles.pageLength}>
          <div style={styles.signatureAlignment}>
            <div
              style={{ width: "50%", display: "flex", alignItems: "baseline" }}
            >
              <label>CREDIT INVESTIGATOR</label>
              <div
                className="text-center"
                style={{ borderBottom: "2px solid", width: "20rem" }}
              >
                <img
                  src={File.get(
                    `${objects?.agentSignature}?applicationId=77c55e9e-2e95-41da-92f1-ccc177ccc59c`
                  )}
                  height={100}
                  width={300}
                  alt="Signature"
                />
                <p className="text-center">{objects?.agent}</p>
              </div>
            </div>
            <div
              style={{ width: "50%", display: "flex", alignItems: "baseline" }}
            >
              <label>Date: </label>
              <div style={{ borderBottom: "2px solid", width: "20rem" }}>
                {objects.dateTimeSubmitted}
              </div>
            </div>
          </div>
          <div style={styles.signatureAlignment}>
            <div
              className="mt-2"
              style={{ width: "50%", display: "flex", alignItems: "baseline" }}
            >
              <label>REVIEWED BY: </label>
              <div
                className="text-center"
                style={{ borderBottom: "2px solid", width: "20rem" }}
              >
                <img
                  src={File.get(
                    `${objects?.qaSignature}?applicationId=77c55e9e-2e95-41da-92f1-ccc177ccc59c`
                  )}
                  height={100}
                  width={300}
                  alt="Signature"
                  className="text-center"
                />
                <p>{objects?.qaOfficer}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Image layouts, split into groups of 6 */}
      {imageChunks.map((chunk, index) => (
        <div
          key={index}
          style={{
            ...styles.mainContainer,
            marginTop: index === 0 ? "10px" : "30px",
          }}
        >
          <div style={styles.pageLength}>{imageLayout(chunk)}</div>
        </div>
      ))}

      {/* <div style={{ ...styles.mainContainer, marginTop: "10px" }}>
        <div style={styles.pageLength}>
          <div style={styles.signatureAlignment}>
            <div
              style={{ width: "50%", display: "flex", alignItems: "baseline" }}
            >
              <label>CREDIT INVESTIGATOR</label>
              <div
                className="text-center"
                style={{ borderBottom: "2px solid", width: "20rem" }}
              >
                <img
                  src={File.get(
                    `${objects?.agentSignature}?applicationId=77c55e9e-2e95-41da-92f1-ccc177ccc59c`
                  )}
                  height={100}
                  width={300}
                  alt="Signature"
                />
                <p className="text-center">{objects?.agent}</p>
              </div>
            </div>
            <div
              style={{ width: "50%", display: "flex", alignItems: "baseline" }}
            >
              <label>Date: </label>
              <div style={{ borderBottom: "2px solid", width: "20rem" }}>
                {objects.dateTimeSubmitted}
              </div>
            </div>
          </div>
          <div style={styles.signatureAlignment}>
            <div
              className="mt-2"
              style={{ width: "50%", display: "flex", alignItems: "baseline" }}
            >
              <label>REVIEWED BY: </label>
              <div
                className="text-center"
                style={{ borderBottom: "2px solid", width: "20rem" }}
              >
                <img
                  src={File.get(
                    `${objects?.qaSignature}?applicationId=77c55e9e-2e95-41da-92f1-ccc177ccc59c`
                  )}
                  height={100}
                  width={300}
                  alt="Signature"
                  className="text-center"
                />
                <p>{objects?.qaOfficer}</p>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}

export default Report2;
