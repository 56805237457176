import BaseFormPresenter from "../../base/BaseFormPresenter";
import {
  findObjectUseCase,
  saveObjectUseCase,
  upsertUseCase,
} from "../../usecases/object";

class QAForm2Presenter extends BaseFormPresenter {
  onChangeObject(object) {
    this.change = object;
    this.object = object;
  }
  componentDidMount() {
    this.init();
    this.getAnswer();
    this.getObject();
  }

  getAnswer() {
    const collection = "evr_forms_answer_mis";
    const params = this.view.getAnswerId();
    console.log("p", params);
    const answerId = this.view.getAnswerId();
    const query = {
      where: { id: answerId },
      // include: [
      //   "form.categories.questions",
      //   "answers",
      //   "answers.question",
      //   "uploads",
      //   "brand",
      // ],
      include: ["form", "answers", "answers.choices", "uploads"],
    };

    if (answerId) {
      this.view.showProgress();
      findObjectUseCase()
        .execute(collection, query)
        .then(([object]) => {
          console.log("get answer object: ", object);
          this.view.hideProgress();
          this.view.setObject(object);
          // this.view.setForm(object.form);
          // this.view.setBrand(object.brand?.id);
          const answer = object;
          this.view.setAnswer(answer);
          // this.view.getFile(object);
        })
        .catch((error) => {
          this.view.hideProgress();
          this.view.showError(error);
        });
    }
  }

  async getObject() {
    const collection = this.view.getCollectionName();
    const id = this.object.id || this.view.getObjectId();
    if (id) {
      const params = {
        include: ["categories", "categories.questions"],
      };
      try {
        this.view.showProgress();
        this.object = await this.getObjectUseCase.execute(collection, id, {
          params,
        });
        this.view.hideProgress();
        this.view.setObject(this.object);
      } catch (error) {
        this.view.hideProgress();
        this.view.showError(error);
      }
    }
  }

  async onClickStatus(status) {
    const url = this.view.getParams();
    const user = this.view.getCurrentUser();
    const objectSend = this.view.state.object;
    const objectAnswer = this.view.getAnswer();
    console.log("OA", objectAnswer);
    const parts = url["*"].split("/");
    const secondPart = parts[1];
    const indexPart = parts[2];
    const query = {
      where: {
        id: secondPart,
      },
    };
    const getEndorsementById = await findObjectUseCase().execute(
      "endorsement",
      query
    );

    const object = getEndorsementById[indexPart];

    object.statusQA = status;
    object.qaSignature = user?.signature;

    if (status === "Approved") {
      const queryUser = {
        where: {
          name: object.agent,
        },
      };
      const findAgent = await findObjectUseCase().execute("users", queryUser);
      const findAgentIndex = findAgent[0];

      const dateTimeSubmitted = new Date(object.dateTimeSubmitted);
      const timeCompleted = this.convertToDateTime(
        object.timeCompleted,
        dateTimeSubmitted
      );
      // console.log("dts", dateTimeSubmitted, "tc", timeCompleted);

      // if (dateTimeSubmitted < timeCompleted) {
      //   findAgentIndex.rank = (findAgentIndex.rank || 0) + 1; // Add points, initialize if undefined
      // } else {
      //   findAgentIndex.rank = (findAgentIndex.rank || 0) + 0.5; // Add points, initialize if undefined
      // }
      if (dateTimeSubmitted < timeCompleted) {
        // findAgentIndex.rank = (findAgentIndex.rank || 0) + 1; // Add points, initialize if undefined
        findAgentIndex.countPoints = findAgentIndex.countPoints || [];
        findAgentIndex.countPoints.push({
          countPoint: 1,
          countEndorserment: 1,
          // createdAt: currentDate,
        });
        // findAgentIndex.countPoints =
        //   (findAgentIndex.numberEndorsement || 0) + 1;
      } else {
        findAgentIndex.countPoints = findAgentIndex.countPoints || 0; // Add points, initialize if undefined
        findAgentIndex.countPoints.push({
          countPoint: 0.5,
          countEndorserment: 1,
          // createdAt: currentDate,
        });
      }
      const { id, upload, ...newObject } = objectSend;

      await saveObjectUseCase().execute("evr_forms_answer_qa", objectAnswer);
      // console.log("findAgent", findAgent);
      const { password, ...userForQAWithoutPassword } = findAgentIndex; // Exclude password
      console.log("userForQAWithoutPassword", userForQAWithoutPassword);
      await upsertUseCase().execute("users", userForQAWithoutPassword);
      // await upsertUseCase().execute("users", findAgentIndex);
    }

    object.reason = this.view.state.reason || "";

    await upsertUseCase().execute("endorsement", object);
    // this.view.submissionSuccess();
    // this.view.showSuccessSnackbar("Successfully saved!");
    this.view.navigateBack();
  }

  convertToDateTime(timeCompleted, referenceDate) {
    const { hour, minute, period } = timeCompleted;

    let hours = parseInt(hour, 10);
    if (period === "PM" && hours !== 12) {
      hours += 12;
    } else if (period === "AM" && hours === 12) {
      hours = 0;
    }

    const completedDate = new Date(referenceDate);
    completedDate.setHours(hours);
    completedDate.setMinutes(parseInt(minute, 10));
    completedDate.setSeconds(0);
    completedDate.setMilliseconds(0);

    return completedDate;
  }

  // async save() {
  //   const collection = this.view.getCollectionName();
  //   const collectionEndorsement = "endorsement";
  //   const collectionUsers = "users";
  //   const currentDate = new Date().toISOString();
  //   const object = this.view.state.object;
  //   console.log("OBJECT SEND", object);
  //   const url = this.view.getParams();

  //   const parts = url["*"].split("/");
  //   console.log("parts", parts);
  //   const secondPart = parts[1];
  //   const indexPart = parts[2];
  //   const query = {
  //     where: {
  //       id: secondPart,
  //     },
  //   };
  //   const queryUser = {
  //     sort: {
  //       createdAt: 1,
  //     },
  //     include: ["All", "roles.name", "location.name"],
  //   };
  //   const users = await findObjectUseCase().execute("users", queryUser);

  //   // Filter users with the role QA
  //   const qaUsers = users.filter((user) =>
  //     user.roles.some((role) => role.name === "QA")
  //   );

  //   // Sort MISUsers by createdAt to establish initial order
  //   qaUsers.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

  //   const sortedQAUsers = qaUsers.sort((a, b) => {
  //     const aEndorsementCount = a.endorsement ? a.endorsement.length : 0;
  //     const bEndorsementCount = b.endorsement ? b.endorsement.length : 0;
  //     console.log("aE", aEndorsementCount);
  //     console.log("bE", bEndorsementCount);
  //     const check = aEndorsementCount === bEndorsementCount ? true : false;
  //     console.log("check", check);

  //     if (aEndorsementCount === bEndorsementCount) {
  //       return new Date(a.createdAt) - new Date(b.createdAt);
  //     }
  //     const minus = aEndorsementCount - bEndorsementCount;
  //     console.log("minus", minus);
  //     return minus;
  //   });

  //   // Select the user with the fewest endorsements, or the earliest created if counts are equal
  //   const userForQA = sortedQAUsers[0];
  //   console.log("Next user for QA:", userForQA);

  //   const findEndorsement = await findObjectUseCase().execute(
  //     collectionEndorsement,
  //     query
  //   );

  //   let endorsementQA = findEndorsement[indexPart];

  //   console.log("findEndorsement", endorsementQA);

  //   let options = {
  //     year: "numeric",
  //     month: "2-digit",
  //     day: "2-digit",
  //     hour: "2-digit",
  //     minute: "2-digit",
  //     second: "2-digit",
  //     hour12: false,
  //     timeZone: "Asia/Manila",
  //   };
  //   const formatter = new Intl.DateTimeFormat("en-GB", options);
  //   let partss = formatter.formatToParts(new Date());
  //   const timeString = `${partss.find((p) => p.type === "year").value}-${
  //     partss.find((p) => p.type === "month").value
  //   }-${partss.find((p) => p.type === "day").value} ${
  //     partss.find((p) => p.type === "hour").value
  //   }:${partss.find((p) => p.type === "minute").value}:${
  //     partss.find((p) => p.type === "second").value
  //   }`;

  //   // endorsementQA.status = "For QA";
  //   // endorsementQA.statusQA = "Pending";
  //   // endorsementQA.createdBy = userForQA.id;
  //   // endorsementQA.timeline = endorsementQA?.timeline || [];
  //   // endorsementQA.timeline?.push({
  //   //   time: timeString,
  //   //   status: "For QA",
  //   // });
  //   // userForQA.endorsement = userForQA.endorsement || [];
  //   // userForQA.endorsement.push({
  //   //   id: secondPart,
  //   //   createdAt: currentDate,
  //   // });

  //   try {
  //     await this.upsertUseCase.execute(collection, object);
  //     await this.upsertUseCase.execute(collectionEndorsement, endorsementQA);
  //     //   await this.upsertUseCase.execute(collectionUsers, userForQA);
  //     this.change = {};
  //   } catch (error) {
  //     throw error; // rethrow the error to be caught by the caller
  //   }
  // }

  async save() {
    const user = this.view.getCurrentUser();
    console.log("user", user);
    const collectionEndorsement = "endorsement";
    const object = this.view.state.object;
    const objectAnswer = this.view.getAnswer();
    console.log("OA", objectAnswer);
    const url = this.view.getParams();
    const parts = url["*"].split("/");
    console.log("parts", parts);
    const secondPart = parts[1];
    const indexPart = parts[2];
    const query = {
      where: {
        id: secondPart,
      },
    };

    const findEndorsement = await findObjectUseCase().execute(
      collectionEndorsement,
      query
    );

    // let endorsementQA = findEndorsement[indexPart];
    let endorsementQA = findEndorsement[0];

    if ("Approved" === "Approved") {
      const queryUser = {
        where: {
          name: endorsementQA.agent,
        },
      };
      const findAgent = await findObjectUseCase().execute("users", queryUser);
      const findAgentIndex = findAgent[0];

      console.log("findAgentIndex: ", findAgentIndex);

      const dateTimeSubmitted = new Date(endorsementQA.dateTimeSubmitted);
      const timeCompleted = this.convertToDateTime(
        endorsementQA.timeCompleted,
        dateTimeSubmitted
      );

      console.log("DTS", dateTimeSubmitted, "TC", timeCompleted);

      if (dateTimeSubmitted < timeCompleted) {
        console.log("HEHE");

        // findAgentIndex.rank = (findAgentIndex.rank || 0) + 1; // Add points, initialize if undefined
        findAgentIndex.countPoints = findAgentIndex.countPoints || [];

        findAgentIndex.countPoints?.push({
          countPoint: 1,
          countEndorserment: 1,
          // createdAt: currentDate,
        });
        // findAgentIndex.countPoints =
        //   (findAgentIndex.numberEndorsement || 0) + 1;
      } else {
        console.log("hihi");
        findAgentIndex.countPoints = findAgentIndex.countPoints || []; // Add points, initialize if undefined
        findAgentIndex.countPoints.push({
          countPoint: 0.5,
          countEndorserment: 1,
          // createdAt: currentDate,
        });

        console.log("findAgentssssss", findAgentIndex);
      }
      console.log("findAgent", findAgentIndex);
      const { password, ...userForQAWithoutPassword } = findAgentIndex; // Exclude password
      console.log("userForQAWithoutPassword", userForQAWithoutPassword);
      await upsertUseCase().execute("users", userForQAWithoutPassword);
      // await upsertUseCase().execute("users", findAgentIndex);
    }

    endorsementQA.statusQA = "Approved";
    endorsementQA.qaSignature = user?.signature;

    console.log("BVR_QA : ", object);
    console.log("Collection Endorsement : ", endorsementQA);
    // const { id, upload, ...newObject } = object;

    try {
      await saveObjectUseCase().execute("evr_forms_answer_qa", objectAnswer);
      await this.upsertUseCase.execute(collectionEndorsement, endorsementQA);
      //   await this.upsertUseCase.execute(collectionUsers, userForQA);
      this.change = {};
    } catch (error) {
      throw error; // rethrow the error to be caught by the caller
    }
  }

  async submit() {
    // if (Object.values(this.change).length === 0) {
    //   this.view.showSuccessSnackbar("Successfully saved!");
    //   return;
    // }
    try {
      this.view.submitting();
      await this.save();
      this.view.submissionSuccess();
      this.view.showSuccessSnackbar("Successfully saved!");
      this.view.navigateTo("/quality-assurance-qa");
    } catch (error) {
      this.view.submissionError(error);
      this.view.showError(error);
    }
  }

  // async save() {
  //   const collection = this.view.getCollectionName();
  //   const user = this.view.getCurrentUser();
  //   const currentDate = new Date().toISOString();
  //   const query = {
  //     sort: {
  //       createdAt: 1,
  //     },
  //     include: ["All", "roles.name", "location.name"],
  //   };

  //   if (this.object.id) {
  //     this.change.id = this.object.id;
  //   }

  //   try {
  //     // Fetch all users
  //     const users = await findObjectUseCase().execute("users", query);
  //     console.log("All users fetched:", users);

  //     // Filter users with the role MIS
  //     const MISUsers = users.filter((user) =>
  //       user.roles.some((role) => role.name === "MIS")
  //     );

  //     // Sort MISUsers by createdAt to establish initial order
  //     MISUsers.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
  //     console.log("establish", MISUsers);

  //     // Sort users by the number of endorsements, then by createdAt if endorsements are equal
  //     const sortedMISUsers = MISUsers.sort((a, b) => {
  //       const aEndorsementCount = a.endorsement ? a.endorsement.length : 0;
  //       const bEndorsementCount = b.endorsement ? b.endorsement.length : 0;
  //       console.log("aE", aEndorsementCount);
  //       console.log("bE", bEndorsementCount);
  //       const check = aEndorsementCount === bEndorsementCount ? true : false;
  //       console.log("check", check);

  //       if (aEndorsementCount === bEndorsementCount) {
  //         return new Date(a.createdAt) - new Date(b.createdAt);
  //       }
  //       const minus = aEndorsementCount - bEndorsementCount;
  //       console.log("minus", minus);
  //       return minus;
  //     });
  //     console.log("sorted[0]", sortedMISUsers);
  //     console.log("sorted[0]", sortedMISUsers[0]);

  //     // Select the user with the fewest endorsements, or the earliest created if counts are equal
  //     const userForEndorsement = sortedMISUsers[0];
  //     console.log("Next user for endorsement:", userForEndorsement);

  //     // Process the endorsement
  //     this.change.createdBy = userForEndorsement.id;
  //     this.change.status = "For Dispatch";
  //     this.change.clientName = user.name;
  //     this.change.borrowerName = this.change.residentName;
  //     this.change.rType = this.view.state.selectedForm.label;
  //     const responseEndorse = await this.upsertUseCase.execute(
  //       collection,
  //       this.change
  //     );
  //     console.log("Endorsement response:", responseEndorse);

  //     // Update the endorsement array for the user
  //     userForEndorsement.endorsement = userForEndorsement.endorsement || [];
  //     userForEndorsement.endorsement.push({
  //       id: responseEndorse.id,
  //       createdAt: currentDate,
  //     });

  //     // Save the updated user
  //     await this.upsertUseCase.execute("users", userForEndorsement);

  //     this.change = {};
  //   } catch (error) {
  //     console.error("Error in save function:", error);
  //     throw error; // Rethrow the error to be caught by the caller
  //   }
  // }
}

export default QAForm2Presenter;
