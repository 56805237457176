import React from "react";
import withRouter from "../../../withRouter";
import {
  countObjectUseCase,
  findObjectUseCase,
} from "../../../usecases/object";
import NavBar from "../../../components/navbar";
import { Button, InfiniteScroll, Progress } from "nq-component";
import InputFactory from "../../../components/InputFactory";
import BaseListPage from "../../../base/BaseListPage";
import EmploymentAuditPresenter from "./EmploymentAuditPresenter";
import TableAudit from "../../../components/TableAudit";

class EmploymentAuditPage extends BaseListPage {
  constructor(props) {
    super(props);
    this.presenter = new EmploymentAuditPresenter(
      this,
      findObjectUseCase(),
      countObjectUseCase()
    );
    this.state = {
      objects: [],
      selected: [],
      loading: true,
      total: 0,
      count: 0,
      selectedOption: [],
    };
  }

  getCollectionName() {
    return "evr_forms_mis";
  }
  onChangeType = (selectedOption) => {
    this.setState({ selectedOption });
  };

  onClickAdd() {
    this.presenter.onClickAdd();
  }
  render() {
    const schema = this.getSchema(this.getCollectionName());
    const { objects, selected, count, progress } = this.state;

    if (!schema) return <Progress />;
    const user = this.getCurrentUser();
    return (
      <>
        <NavBar />
        <div className="overflow-auto">
          <InfiniteScroll
            className="h-100"
            loadMore={this.loadMore.bind(this)}
            hasMore={!progress && count > objects.length}
          >
            <div className="p-3 p-lg-4">
              <div className="d-flex justify-content-between align-items-center">
                <h1 className="fw-bold text-capitalize">
                  {/* {schema.label || this.getCollectionName()} */}
                  Employment Verification
                </h1>
                {selected.length > 0 ? (
                  <div>
                    <span className="ms-2">Selected: </span>
                    <span className="fs-sm text-nowrap">{selected.length}</span>
                    <span className="ms-1">of </span>
                    <span className="fs-sm text-nowrap">{count}</span>
                  </div>
                ) : (
                  <div>
                    <span className="ms-2">Total: </span>
                    <span className="fs-sm text-nowrap">{objects.length}</span>
                    <span className="ms-1">of </span>
                    <span className="fs-sm text-nowrap">{count}</span>
                  </div>
                )}
              </div>

              <TableAudit
                fields={schema.fields}
                objects={objects}
                client={true}
                // selectable
                collapsable
                excludeFields={Object.keys(schema.fields).reduce(
                  (acc, key) => {
                    const options = schema.fields[key];
                    if (options.read === false) {
                      acc.push(key);
                    }
                    switch (options._type || options.type) {
                      case "Relation":
                      case "Array":
                      case "Object":
                      case "File":
                        acc.push(key);
                        break;
                      default:
                    }
                    return acc;
                  },
                  [
                    "acl",
                    "password",
                    "username",
                    "firstName",
                    "middleName",
                    "lastName",
                    "picture",
                    "signature",
                    "email",
                    "text",
                    "upload",
                    "id",
                    "clientName",
                  ]
                )}
                selected={selected}
                onSelect={this.onSelect.bind(this)}
                onSelectAll={this.onSelectAll.bind(this)}
                progress={progress}
                onClickItem={this.onClickItem.bind(this)}
                // onCollapse={this.onCollapse.bind(this)}
                className="mt-3"
              />
            </div>
          </InfiniteScroll>
        </div>
        <div className="position-fixed bottom-0 end-0 m-4">
          <Button
            className="btn shadow-sm"
            onClick={this.onClickAdd.bind(this)}
            style={{
              width: "50px",
              height: "50px",
              borderRadius: "25px",
              backgroundColor: "#fdcb5b",
            }}
          >
            <i
              className="bi bi-plus-lg"
              style={{ fontSize: "20px", color: "black" }}
            />
          </Button>
        </div>
      </>
    );
  }
}

export default withRouter(EmploymentAuditPage);
