import React from "react";
import withRouter from "../../withRouter";
import BaseListPage from "../../base/BaseListPage";
import { countObjectUseCase, findObjectUseCase } from "../../usecases/object";
import NavBar from "../../components/navbar";
import {
  Button,
  InfiniteScroll,
  Progress,
  SelectSearch,
  dialog,
} from "nq-component";
import InputFactory from "../../components/InputFactory";
import Search from "../../components/Search";
import TableAudit from "../../components/TableAudit";

import QualityAssurancePresenter from "./QualityAssurancePresenter";
import classNames from "../../classNames";
import printComponent from "../../printComponent";
// import Report1 from "../../components/Reports/Report1";
// import Report2 from "../../components/Reports/Report2";
// import Report3 from "../../components/Reports/Report3";
import html2pdf from "html2pdf.js";
import "./report.css";
import { saveFileUseCase } from "../../usecases/file";
import InputFile from "../../components/InputFile";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import Report1 from "../../components/Report2/Report1";
import Report2 from "../../components/Report2/Report2";
import { PrintRVRForm } from "../../components/Report2/print-rvr/PrintRVRForm";
// import Report3 from "../../components/Reports/Report3";
import Report3 from "../../components/Report2/Report3";

async function getBase64ImageFromURL(url) {
  const response = await fetch(url, { mode: "cors" }); // Ensure CORS is enabled
  const blob = await response.blob();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      resolve(reader.result); // This is the base64 encoded image
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
}

class QualityAssuranceQAPage extends BaseListPage {
  constructor(props) {
    super(props);
    this.state = {
      objects: [],
      selected: [],
      loading: true,
      total: 0,
      count: 0,
      report: [],
      index: "",
      linkUrl: "",
      sendMailIndex: null,
    };
    this.presenter = new QualityAssurancePresenter(
      this,
      findObjectUseCase(),
      countObjectUseCase()
    );
    this.contractPDF = React.createRef();
  }

  getCollectionName() {
    return "endorsement";
  }

  componentDidMount() {
    this.presenter.componentDidMount();
  }

  load() {
    return dialog.fire({
      html: (
        <div className="text-center mt-1">
          <Progress />
          <h6>Processing... Please wait.</h6>
        </div>
      ),
      footer: false,
    });
  }

  onClickAdd() {
    this.presenter.onClickAdd();
  }

  onClickItem(index, field) {
    this.presenter.onClickItem(index, field);
  }

  onClickView(index, field) {
    this.presenter.onClickView(index, field);
  }

  onClickItemAudit(index, field) {
    this.presenter.onClickItemAudit(index, field);
  }

  onClickComparison(index, field) {
    this.presenter.onClickComparison(index, field);
  }

  // async onClickExportPDF(index) {
  //   const setPrint = this.state?.objects[index];
  //   console.log("setPrint", setPrint);
  //   dialog.close();
  //   this.load();
  //   await this.presenter.findReport(index);
  //   console.log("THIS IS REPORT", this.state.report);
  //   this.setState({ sendMailIndex: index }, () => {
  //     const element = this.contractPDF.current;

  //     if (element) {
  //       const opt = {
  //         margin: 0.5,
  //         filename: "report.pdf",
  //         image: { type: "jpeg", quality: 0.98 },
  //         html2canvas: {
  //           scale: 3,
  //           scrollX: 0,
  //           scrollY: 0,
  //           windowWidth: element.scrollWidth || document.body.scrollWidth,
  //           windowHeight: element.scrollHeight || document.body.scrollHeight,
  //         },
  //         jsPDF: {
  //           unit: "pt",
  //           format: "a4",
  //           orientation:
  //             setPrint?.rType === "EMPLOYMENT VERIFICATION (EVR)"
  //               ? "landscape"
  //               : "portrait",
  //         },
  //       };

  //       html2pdf()
  //         .from(element)
  //         .set(opt)
  //         .output("blob")
  //         .then((pdfBlob) => {
  //           pdfBlob.name = "report.pdf";

  //           saveFileUseCase()
  //             .execute(pdfBlob)
  //             .then((result) => {
  //               const linkUrl = `${result.url}?applicationId=77c55e9e-2e95-41da-92f1-ccc177ccc59c`;
  //               console.log("result", linkUrl);
  //               this.setState({ linkUrl }, () => {
  //                 this.presenter.sendEmail(index, this.state.linkUrl);
  //               });
  //             })
  //             .catch((error) => {
  //               console.log("error", error);
  //             });
  //         });
  //     } else {
  //       alert("element ref is not available yet");
  //     }
  //   });
  // }

  async onClickExportPDF(index) {
    const setPrint = this.state?.objects[index];
    console.log("setPrint", setPrint);
    dialog.close();
    this.load();
    await this.presenter.findReport(index);
    console.log("THIS IS REPORT", this.state.report);
    this.setState({ sendMailIndex: index }, async () => {
      const element = this.contractPDF.current;

      if (element) {
        // Get all the image elements
        const images = element.querySelectorAll("img");

        // Replace each image src with its base64 equivalent
        const imagePromises = Array.from(images).map(async (img) => {
          try {
            const base64 = await getBase64ImageFromURL(img.src);
            img.src = base64; // Replace the src with base64 encoded image
          } catch (error) {
            console.error("Error loading image:", error);
          }
        });

        try {
          await Promise.all(imagePromises);
          console.log("All images replaced with base64!");

          // Set options for html2pdf
          const opt = {
            margin: 0.5,
            filename: "report.pdf",
            image: { type: "jpeg", quality: 0.98 },
            html2canvas: {
              scale: 3,
              scrollX: 0,
              scrollY: 0,
              windowWidth: element.scrollWidth || document.body.scrollWidth,
              windowHeight: element.scrollHeight || document.body.scrollHeight,
              useCORS: true,
            },
            jsPDF: {
              unit: "pt",
              format: "a4",
              orientation:
                setPrint?.rType === "EMPLOYMENT VERIFICATION (EVR)"
                  ? "landscape"
                  : "portrait",
            },
          };

          html2pdf()
            .from(element)
            .set(opt)
            .output("blob")
            .then((pdfBlob) => {
              pdfBlob.name = "report.pdf";

              saveFileUseCase()
                .execute(pdfBlob)
                .then((result) => {
                  const linkUrl = `${result.url}?applicationId=77c55e9e-2e95-41da-92f1-ccc177ccc59c`;
                  console.log("result", linkUrl);
                  this.setState({ linkUrl }, () => {
                    this.presenter.sendEmail(index, this.state.linkUrl);
                  });
                })
                .catch((error) => {
                  console.log("error", error);
                });
              const downloadLink = document.createElement("a");
              downloadLink.href = URL.createObjectURL(pdfBlob);
              downloadLink.download = "report.pdf";
              document.body.appendChild(downloadLink);
              downloadLink.click();
              document.body.removeChild(downloadLink);
            });
        } catch (error) {
          console.error("Error processing images:", error);
        }
      } else {
        alert("element ref is not available yet");
      }
    });
  }

  async onClickExportPDF2(index) {
    const setPrint = this.state?.objects[index];
    console.log("setPrint", setPrint);
    // dialog.close();
    this.load();
    await this.presenter.findReport(index);
    console.log("THIS IS REPORT", this.state.report);
    this.setState({ sendMailIndex: index }, async () => {
      const element = this.contractPDF.current;

      if (element) {
        // Get all the image elements
        const images = element.querySelectorAll("img");

        // Replace each image src with its base64 equivalent
        const imagePromises = Array.from(images).map(async (img) => {
          try {
            const base64 = await getBase64ImageFromURL(img.src);
            img.src = base64; // Replace the src with base64 encoded image
          } catch (error) {
            console.error("Error loading image:", error);
          }
        });

        try {
          await Promise.all(imagePromises);
          console.log("All images replaced with base64!");

          // Set options for html2pdf
          const opt = {
            margin: 0.5,
            filename: "report.pdf",
            image: { type: "jpeg", quality: 0.98 },
            html2canvas: {
              scale: 3,
              scrollX: 0,
              scrollY: 0,
              windowWidth: element.scrollWidth || document.body.scrollWidth,
              windowHeight: element.scrollHeight || document.body.scrollHeight,
              useCORS: true,
            },
            jsPDF: {
              unit: "pt",
              format: "a4",
              orientation:
                setPrint?.rType === "EMPLOYMENT VERIFICATION (EVR)"
                  ? "landscape"
                  : "portrait",
            },
          };

          html2pdf()
            .from(element)
            .set(opt)
            .output("blob")
            .then((pdfBlob) => {
              pdfBlob.name = "report.pdf";

              saveFileUseCase()
                .execute(pdfBlob)
                .then((result) => {
                  const linkUrl = `${result.url}?applicationId=77c55e9e-2e95-41da-92f1-ccc177ccc59c`;
                  console.log("result", linkUrl);
                  this.setState({ linkUrl }, () => {
                    // this.presenter.sendEmail(index, this.state.linkUrl);
                  });
                })
                .catch((error) => {
                  console.log("error", error);
                });
              const downloadLink = document.createElement("a");
              downloadLink.href = URL.createObjectURL(pdfBlob);
              downloadLink.download = "report.pdf";
              document.body.appendChild(downloadLink);
              downloadLink.click();
              document.body.removeChild(downloadLink);
              dialog.close();
            });
        } catch (error) {
          console.error("Error processing images:", error);
        }
      } else {
        alert("element ref is not available yet");
      }
    });
  }

  onClickDownload(index) {
    this.presenter.findReport(index);
    this.presenter.onClickDownload(index);
  }

  exportPDF() {
    printComponent(this.contractPDF.current, "Tasks");
  }

  onClickItemSend(index) {
    dialog.fire({
      html: (
        <div className="text-end">
          <div className="text-center p-4">
            <i
              className={classNames("bi bi-question-circle", "text-warning")}
              style={{ fontSize: "5rem" }}
            ></i>

            <h3 className="m-0">Are you sure want to send?</h3>
            <button
              className="btn mb-2 mt-2 me-2"
              style={{ backgroundColor: "#EBBD2F" }}
              onClick={() => dialog.close()}
            >
              Cancel
            </button>
            <button
              className="btn mb-2 mt-2 me-2"
              style={{ backgroundColor: "#EBBD2F" }}
              onClick={() => this.onClickExportPDF(index)}
            >
              Confirm
            </button>
          </div>
        </div>
      ),
      footer: false,
    });
    this.presenter.findReport(index);
  }

  onCollapse(index, object) {
    const user = this.getCurrentUser();

    return (
      <>
        {/* <div className="d-flex">
          {object.picture && (
            <div
              className="mb-3"
              style={{
                width: "100px",
                height: "100px",
              }}
            >
              <img
                className="w-100 h-100"
                style={{ objectFit: "cover" }}
                src={object.picture}
                alt="Profile"
              />
            </div>
          )}
          <ul className="list-unstyled ms-1">
            <li>
              <span className="ms-2 fw-light">Name:</span>
              <span className="fs-sm text-nowrap"> {object.residentName}</span>
            </li>

            <li>
              <span className="ms-2 fw-light">Location: </span>
              <span className="fs-sm text-nowrap">{object.location.name}</span>
            </li>
            <li>
              <span className="ms-2 fw-light">Date: </span>
              <span className="fs-sm text-nowrap">
                {object.updatedAt.substring(0, 10)}
              </span>
            </li>
            <li>
              <span className="ms-2 fw-light">Time to be Completed: </span>
              <span className="fs-sm text-nowrap">
                {object.timeCompleted.hour}:{object.timeCompleted.minute}{" "}
                {object.timeCompleted.period}
              </span>
            </li>
          </ul>
        </div> */}
        <div className="d-flex">
          {object.picture && (
            <div
              className="mb-3"
              style={{
                width: "100px",
                height: "100px",
              }}
            >
              <img
                className="w-100 h-100"
                style={{ objectFit: "cover" }}
                src={object.picture}
              />
            </div>
          )}
          <ul className="list-unstyled ms-1">
            <li>
              <span className="ms-2 fw-bold" style={{ color: "#003869" }}>
                Report Type:
              </span>
              <span className="fs-sm text-nowrap"> {object.rType}</span>
            </li>
            <hr />
            <li>
              <span className="ms-2 fw-bold">
                {object.rType === "BUSINESS VERIFICATION (BVR)"
                  ? "Business Name: "
                  : "Resident Name: "}
              </span>
              <span className="fs-sm text-nowrap"> {object.residentName}</span>
            </li>

            <li>
              <span className="ms-2 fw-bold">
                {" "}
                {object.rType === "BUSINESS VERIFICATION (BVR)"
                  ? "Business Address: "
                  : "Residential Address: "}
              </span>
              <span className="fs-sm text-nowrap">
                {object.residentialAddress}, {object.barangay}, {object.city},{" "}
                {object.province} {object.region}
              </span>
            </li>
            <li>
              <span className="ms-2 fw-bold">
                {" "}
                {object.rType === "BUSINESS VERIFICATION (BVR)"
                  ? "Business Contact Number: "
                  : "Contact Number: "}
              </span>
              <span className="fs-sm text-nowrap">
                {object.residentContact}
              </span>
            </li>
            <hr />
            <li>
              <span className="ms-2 fw-bold">
                {object.rType === "BUSINESS VERIFICATION (BVR)"
                  ? "Subject Name"
                  : "Employer's Name:"}
              </span>
              <span className="fs-sm text-nowrap"> {object.employerName}</span>
            </li>

            <li>
              <span className="ms-2 fw-bold">
                {" "}
                {object.rType === "BUSINESS VERIFICATION (BVR)"
                  ? "Subject Address: "
                  : "Employer's Address: "}{" "}
              </span>
              <span className="fs-sm text-nowrap">
                {object.employerAddress}
              </span>
            </li>
            <li>
              <span className="ms-2 fw-bold">Contact Number: </span>
              <span className="fs-sm text-nowrap">
                {object.employerContact}
              </span>
            </li>
            <hr />

            {/* <li>
              <span className="ms-2 fw-bold">Name:</span>
              <span className="fs-sm text-nowrap"> {object.residentName}</span>
            </li>

            <li>
              <span className="ms-2 fw-bold">Location: </span>
              <span className="fs-sm text-nowrap">{object.location.name}</span>
            </li>
            <li>
              <span className="ms-2 fw-bold">Date: </span>
              <span className="fs-sm text-nowrap">
                {object.updatedAt.substring(0, 10)}
              </span>
            </li> */}
            <li>
              <span className="ms-2 fw-bold">Time to be Completed: </span>
              <span className="fs-sm text-nowrap">
                {object.timeCompleted.hour}:{object.timeCompleted.minute}{" "}
                {object.timeCompleted.period}
              </span>
            </li>
            <hr />
            <li>
              <span className="ms-2 fw-bold">Note:</span>
              <span
                className="fs-sm text-nowrap font-italic"
                style={{ fontStyle: "italic" }}
              >
                {" "}
                {object.notesCI}
              </span>
            </li>
          </ul>
        </div>

        <div className="d-flex">
          <div className="me-1">
            {object.statusQA === "Pending" ? (
              <button
                className="btn text-white"
                onClick={() => this.onClickItemAudit(index)}
                style={{ backgroundColor: "#04376a" }}
              >
                <i className="bi bi-pencil-square me-1"></i>
                {user.roles[0].id === "QA" ? "VIEW" : "EDIT"}
              </button>
            ) : null}
          </div>
          <div className="me-1">
            {object.statusQA === "Approved" ? (
              <button
                className="btn"
                onClick={() => this.onClickItemSend(index)}
                // onClick={() => this.exportPDF(index)}
                style={{ backgroundColor: "#fdcb5b" }}
              >
                <i className="bi bi-pencil-square me-1"></i>SEND
              </button>
            ) : null}
          </div>
          <div className="me-1">
            {object.statusQA === "Done" ? (
              <button
                className="btn"
                onClick={() => this.onClickComparison(index)}
                // onClick={() => this.exportPDF(index)}
                style={{ backgroundColor: "#fdcb5b" }}
              >
                <i className="bi bi-eye-fill me-1"></i>VIEW FORMS
              </button>
            ) : null}
          </div>
          <div className="me-1">
            {object.statusQA === "Done" ? (
              <button
                className="btn"
                // onClick={() => this.onClickComparison(index)}
                // onClick={() => this.onClickDownload(index)}
                onClick={() => this.onClickExportPDF2(index)}
                // onClick={() => this.exportPDF(index)}
                style={{ backgroundColor: "#fdcb5b" }}
              >
                <i className="bi bi-arrow-bar-down me-1"></i>DOWNLOAD PDF
              </button>
            ) : null}
          </div>
        </div>
      </>
    );
  }

  renderReport() {
    const { objects, report, index, questions, labelQuestion } = this.state;
    console.log("report", report);
    console.log("objects", objects);
    console.log("index", this.state?.sendMailIndex);
    // console.log("report object", questions);
    const user = this.getCurrentUser();

    if (!report || report.length === 0) {
      // Handle the case where report is undefined or an empty array
      return <div>No report available</div>;
      // return <Report1 objects={objects} report={report} user={user} />;
    }

    const reportType = objects[this.state?.sendMailIndex]?.rType;
    console.log("RT", reportType);

    switch (reportType) {
      case "BUSINESS VERIFICATION (BVR)":
        return (
          <Report1
            objects={objects[this.state?.sendMailIndex]}
            report={report}
            user={user}
            questions={questions}
            labelQuestion={labelQuestion}
          />
        );
      case "EMPLOYMENT VERIFICATION (EVR)":
        return (
          <Report2
            objects={objects[this.state?.sendMailIndex]}
            report={report}
            user={user}
          />
        );
      case "RESIDENCE VERIFICATION (RVR)":
        return (
          <Report3
            objects={objects[this.state?.sendMailIndex]}
            report={report}
            user={user}
          />
        );
      default:
        return <div>Unknown report type</div>;
    }
  }

  onChangeStatus(value) {
    this.setState({ status: value });
    if (value.value === "Pending") {
      // this.onChangeFilter("String", "For QA", "status");
      this.onChangeFilter("String", value.value, "status");
    } else if (
      value.value === "Disapprove" ||
      value.value === "Approved" ||
      value.value === "Pending"
    ) {
      this.onChangeFilter("String", value.value, "statusQA");
    } else {
      this.onChangeFilter("String", value.value, "status");
    }
  }

  render() {
    const schema = this.getSchema(this.getCollectionName());

    const { objects, selected, count, progress, report } = this.state;
    console.log("clp", objects);
    console.log("report", report);

    if (!schema) return <Progress />;
    const user = this.getCurrentUser();
    return (
      <>
        <NavBar />
        <div className="d-none">
          <div ref={this.contractPDF} id="contractPDF">
            {this.renderReport()}
          </div>
        </div>

        <div className="overflow-auto">
          <InfiniteScroll
            className="h-100"
            loadMore={this.loadMore.bind(this)}
            hasMore={!progress && count > objects.length}
          >
            <div className="p-3 p-lg-4">
              <div className="d-flex justify-content-between align-items-center">
                <h1 className="fw-bold text-capitalize">
                  {schema.label || "Quality Assurance"}
                </h1>

                {selected.length > 0 ? (
                  <div>
                    <span className="ms-2">Selected: </span>
                    <span className="fs-sm text-nowrap">{selected.length}</span>
                    <span className="ms-1">of </span>
                    <span className="fs-sm text-nowrap">{count}</span>
                  </div>
                ) : (
                  <div>
                    <span className="ms-2">Total: </span>
                    <span className="fs-sm text-nowrap">{objects.length}</span>
                    <span className="ms-1">of </span>
                    <span className="fs-sm text-nowrap">{count}</span>
                  </div>
                )}
              </div>
              <div className="d-flex">
                <SelectSearch
                  onChange={(value) => this.onChangeStatus(value)}
                  onClickClear={() =>
                    this.onChangeStatus({ label: "", value: "" })
                  }
                  value={this.state.status}
                  options={["Pending", "Disapprove", "Approved", "Done"].map(
                    (item) => ({
                      label: item,
                      value: item,
                    })
                  )}
                />
              </div>
              <div className="d-flex mt-3">
                {Object.keys(schema.filters1 || {}).map((field) => {
                  let { type, ...options } = schema.filters[field];
                  return (
                    <InputFactory
                      key={field}
                      className="ms-1"
                      type={type}
                      field={field}
                      where={{}}
                      onChange={this.onChangeFilter.bind(this, type)}
                      {...options}
                    />
                  );
                })}
              </div>
              <Search
                schemas={this.getSchemas()}
                className="mt-3"
                onSubmit={this.searchSubmit.bind(this)}
                fields={schema.fields}
              />
              <TableAudit
                fields={schema.fields}
                objects={objects}
                selectable
                collapsable
                excludeFields={Object.keys(schema.fields).reduce(
                  (acc, key) => {
                    const options = schema.fields[key];
                    if (options.read === false) {
                      acc.push(key);
                    }
                    switch (options._type || options.type) {
                      case "Relation":
                      case "Array":
                      case "Object":
                      case "File":
                        acc.push(key);
                        break;
                      default:
                    }
                    return acc;
                  },
                  [
                    "acl",
                    "password",
                    "username",
                    "firstName",
                    "middleName",
                    "lastName",
                    "picture",
                    "signature",
                    "email",
                    "residentialAddress",
                    "residentContact",
                    "employerName",
                    "employerAddress",
                    "employerContact",
                    "reportType",
                    "rate",
                    "dispatchType",
                    // "rType",
                    "loanTitle",
                    "clientName",
                    "borrowerName",
                    "status",
                    "id",
                    "location",
                    "residentName",
                    "note",
                  ]
                )}
                selected={selected}
                onSelect={this.onSelect.bind(this)}
                onSelectAll={this.onSelectAll.bind(this)}
                progress={progress}
                onClickItem={this.onClickItem.bind(this)}
                onCollapse={this.onCollapse.bind(this)}
                className="mt-3"
              />
            </div>
          </InfiniteScroll>
        </div>
      </>
    );
  }
}

export default withRouter(QualityAssuranceQAPage);
